export enum NotificationActionEnum {
  AcceptedInvite = 'accepted_invite',
  AcceptedInviteRequest = 'accepted_invite_request',
  AssignedYou = 'assigned_you',
  ClosedMatter = 'closed_matter',
  ClosedOrganisation = 'closed_organisation',
  CreatedEnquiry = 'created_enquiry',
  InviteRequest = 'invite_request',
  InvitedYou = 'invited_you',
  RejectedInvite = 'rejected_invite',
  RejectedInviteRequest = 'rejected_invite_request',
  RepliedToEnquiry = 'replied_to_enquiry',
  SubmittedMatterStepForm = 'submitted_matter_step_form',
  UpdatedEnquiryStatus = 'updated_enquiry_status',
  UpdatedMatterStepStatus = 'updated_matter_step_status',
  UploadedDocument = 'uploaded_document',
};
