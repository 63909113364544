/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  StepFormsPost,
  StepForm,
  stepFormsPostFetch,
  stepFormsStepFormIdGetFetch,
  StepFormsStepFormIdPatch,
  stepFormsStepFormIdPatchFetch
} from '@/common/gen';

export const stepFormsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: StepFormsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepForm>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepFormsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepForm>({tag: 'stepFormsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepForm, error: null };
};

export const stepFormsStepFormIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepFormId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepForm>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepFormsStepFormIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepForm>({tag: 'stepFormsStepFormIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepForm, error: null };
};

export const stepFormsStepFormIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: StepFormsStepFormIdPatch,
  pathParams: {
    stepFormId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepForm>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepFormsStepFormIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepForm>({tag: 'stepFormsStepFormIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepForm, error: null };
};
