export * from './codegen-index';

export class SwrError extends Error {
  statusCode?: number;
  
  constructor(message?: string, statusCode?: number, options?: ErrorOptions) {
    super(message)
    this.name = 'SwrError';
    this.statusCode = statusCode;
  }
}