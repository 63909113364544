/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  Logos,
  LogosPost,
  Logo,
  LogoUpload
} from '@/common/gen';

export const logosTag = (): string => {
  return `/logos`;
};

export const logosLogoIdUploadCompleteTag = ({
  pathParams,
}:{
  pathParams: {
    logoId: string,
  },
}): string => {
  return `/logos/${encodeURIComponent(pathParams.logoId)}/upload/complete`;
};

export const logosLogoIdUploadTag = ({
  pathParams,
}:{
  pathParams: {
    logoId: string,
  },
}): string => {
  return `/logos/${encodeURIComponent(pathParams.logoId)}/upload`;
};
export const logosGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    organisationId?: string,
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Logos>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/logos?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        logosTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const logosPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: LogosPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Logo>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/logos`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        logosTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const logosLogoIdUploadCompletePostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    logoId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Logo>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/logos/${encodeURIComponent(pathParams.logoId)}/upload/complete`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        logosLogoIdUploadCompleteTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const logosLogoIdUploadGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    logoId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<LogoUpload>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/logos/${encodeURIComponent(pathParams.logoId)}/upload`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        logosLogoIdUploadTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
