/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  FormTemplateContents,
  formTemplateContentsGetFetch,
  FormTemplateContentsPost,
  FormTemplateContent,
  formTemplateContentsPostFetch,
  formTemplateContentsFormTemplateContentIdGetFetch
} from '@/common/gen';

export const formTemplateContentsGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    formTemplateId?: string,
    limit?: number,
    offset?: number,
    latest?: boolean,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplateContents>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplateContentsGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplateContents>({tag: 'formTemplateContentsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplateContents, error: null };
};

export const formTemplateContentsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: FormTemplateContentsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplateContent>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplateContentsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplateContent>({tag: 'formTemplateContentsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplateContent, error: null };
};

export const formTemplateContentsFormTemplateContentIdGet = async ({
  pathParams,
  queryParams,
  ...requestInitArgs
}:{
  pathParams: {
    formTemplateContentId: string,
  },
  queryParams: {
    matterStepId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplateContent>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplateContentsFormTemplateContentIdGetFetch({
    pathParams,
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplateContent>({tag: 'formTemplateContentsFormTemplateContentIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplateContent, error: null };
};
