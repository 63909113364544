/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  SortDirectionEnum,
  EnquirySortByEnum,
  Enquiries,
  enquiriesGetFetch,
  EnquiriesPost,
  Enquiry,
  enquiriesPostFetch,
  EnquiryMeta,
  enquiriesMetaGetFetch,
  EnquiryStatusEnum,
  EnquiryOverviews,
  enquiriesOverviewGetFetch,
  enquiriesEnquiryIdGetFetch,
  EnquiriesEnquiryIdPatch,
  enquiriesEnquiryIdPatchFetch,
  EnquiryPermissions,
  enquiriesEnquiryIdPermissionsGetFetch,
  EnquiriesStatusPatch,
  enquiriesEnquiryIdStatusPatchFetch
} from '@/common/gen';

export const enquiriesGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
    sortDirection?: SortDirectionEnum,
    sortBy?: EnquirySortByEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Enquiries>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await enquiriesGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Enquiries>({tag: 'enquiriesGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Enquiries, error: null };
};

export const enquiriesPost = async ({
  body,
  ...requestInitArgs
}:{
  body: EnquiriesPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Enquiry>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await enquiriesPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Enquiry>({tag: 'enquiriesPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Enquiry, error: null };
};

export const enquiriesMetaGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<EnquiryMeta>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await enquiriesMetaGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<EnquiryMeta>({tag: 'enquiriesMetaGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as EnquiryMeta, error: null };
};

export const enquiriesOverviewGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    enquiryStatuses?: EnquiryStatusEnum[],
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
    sortBy?: EnquirySortByEnum,
    sortDirection?: SortDirectionEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<EnquiryOverviews>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await enquiriesOverviewGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<EnquiryOverviews>({tag: 'enquiriesOverviewGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as EnquiryOverviews, error: null };
};

export const enquiriesEnquiryIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    enquiryId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Enquiry>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await enquiriesEnquiryIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Enquiry>({tag: 'enquiriesEnquiryIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Enquiry, error: null };
};

export const enquiriesEnquiryIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: EnquiriesEnquiryIdPatch,
  pathParams: {
    enquiryId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Enquiry>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await enquiriesEnquiryIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Enquiry>({tag: 'enquiriesEnquiryIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Enquiry, error: null };
};

export const enquiriesEnquiryIdPermissionsGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    enquiryId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<EnquiryPermissions>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await enquiriesEnquiryIdPermissionsGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<EnquiryPermissions>({tag: 'enquiriesEnquiryIdPermissionsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as EnquiryPermissions, error: null };
};

export const enquiriesEnquiryIdStatusPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: EnquiriesStatusPatch,
  pathParams: {
    enquiryId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Enquiry>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await enquiriesEnquiryIdStatusPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Enquiry>({tag: 'enquiriesEnquiryIdStatusPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Enquiry, error: null };
};
