/*
* This file is auto generated - do not edit manually
*/
"use client"

import { Route } from "next";
import { useRouter } from "next/navigation";
import useSWR, { SWRConfiguration } from "swr";
import { SwrError } from ".";
import {
  FormTemplateContents,
  formTemplateContentsGetFetch,
  formTemplateContentsTag,
  FormTemplateContent,
  formTemplateContentsFormTemplateContentIdGetFetch,
  formTemplateContentsFormTemplateContentIdTag
} from '@/common/gen';

export const useFormTemplateContentsGet = ({
    queryParams,
  }:{
  queryParams: {
    formTemplateId?: string,
    limit?: number,
    offset?: number,
    latest?: boolean,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<FormTemplateContents>(
    formTemplateContentsTag(),
    () => formTemplateContentsGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useFormTemplateContentsFormTemplateContentIdGet = ({
    pathParams,
    queryParams,
  }:{
  pathParams: {
    formTemplateContentId: string,
  },
  queryParams: {
    matterStepId?: string,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<FormTemplateContent>(
    formTemplateContentsFormTemplateContentIdTag({ pathParams }),
    () => formTemplateContentsFormTemplateContentIdGetFetch({pathParams, queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}

