/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  StepStatusEnum,
  Steps,
  stepsGetFetch,
  StepsPost,
  Step,
  stepsPostFetch,
  StepMeta,
  stepsMetaGetFetch,
  StepOverviews,
  stepsOverviewGetFetch,
  stepsStepIdGetFetch,
  StepsStepIdPatch,
  stepsStepIdPatchFetch,
  StepPermissions,
  stepsStepIdPermissionsGetFetch
} from '@/common/gen';

export const stepsGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    search?: string,
    stepStatuses?: StepStatusEnum[],
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Steps>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepsGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Steps>({tag: 'stepsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Steps, error: null };
};

export const stepsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: StepsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Step>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Step>({tag: 'stepsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Step, error: null };
};

export const stepsMetaGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepMeta>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepsMetaGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepMeta>({tag: 'stepsMetaGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepMeta, error: null };
};

export const stepsOverviewGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    search?: string,
    stepStatuses?: StepStatusEnum[],
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepOverviews>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepsOverviewGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepOverviews>({tag: 'stepsOverviewGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepOverviews, error: null };
};

export const stepsStepIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Step>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepsStepIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Step>({tag: 'stepsStepIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Step, error: null };
};

export const stepsStepIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: StepsStepIdPatch,
  pathParams: {
    stepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Step>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepsStepIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Step>({tag: 'stepsStepIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Step, error: null };
};

export const stepsStepIdPermissionsGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepPermissions>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepsStepIdPermissionsGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepPermissions>({tag: 'stepsStepIdPermissionsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepPermissions, error: null };
};
