/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  OrganisationPermissionsEnum,
  Organisations,
  OrganisationsPost,
  Organisation,
  OrganisationsOrganisationIdPatch,
  OrganisationMembers,
  OrganisationsRolePatch,
  OrganisationMember,
  OrganisationPermissions,
  OrganisationProfile,
  OrganisationsProfilePatch,
  OrganisationRoles
} from '@/common/gen';

export const organisationsTag = (): string => {
  return `/organisations`;
};

export const organisationsOrganisationIdCloseTag = ({
  pathParams,
}:{
  pathParams: {
    organisationId: string,
  },
}): string => {
  return `/organisations/${encodeURIComponent(pathParams.organisationId)}/close`;
};

export const organisationsOrganisationIdTag = ({
  pathParams,
}:{
  pathParams: {
    organisationId: string,
  },
}): string => {
  return `/organisations/${encodeURIComponent(pathParams.organisationId)}`;
};

export const organisationsOrganisationIdMembersTag = ({
  pathParams,
}:{
  pathParams: {
    organisationId: string,
  },
}): string => {
  return `/organisations/${encodeURIComponent(pathParams.organisationId)}/members`;
};

export const organisationsOrganisationIdMembersLeaveTag = ({
  pathParams,
}:{
  pathParams: {
    organisationId: string,
  },
}): string => {
  return `/organisations/${encodeURIComponent(pathParams.organisationId)}/members/leave`;
};

export const organisationsOrganisationIdMembersUserIdRoleTag = ({
  pathParams,
}:{
  pathParams: {
    organisationId: string,
    userId: string,
  },
}): string => {
  return `/organisations/${encodeURIComponent(pathParams.organisationId)}/members/${encodeURIComponent(pathParams.userId)}/role`;
};

export const organisationsOrganisationIdPermissionsTag = ({
  pathParams,
}:{
  pathParams: {
    organisationId: string,
  },
}): string => {
  return `/organisations/${encodeURIComponent(pathParams.organisationId)}/permissions`;
};

export const organisationsOrganisationIdProfileTag = ({
  pathParams,
}:{
  pathParams: {
    organisationId: string,
  },
}): string => {
  return `/organisations/${encodeURIComponent(pathParams.organisationId)}/profile`;
};

export const organisationsOrganisationIdRolesTag = ({
  pathParams,
}:{
  pathParams: {
    organisationId: string,
  },
}): string => {
  return `/organisations/${encodeURIComponent(pathParams.organisationId)}/roles`;
};
export const organisationsGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationPermission?: OrganisationPermissionsEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Organisations>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/organisations?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        organisationsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: OrganisationsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Organisation>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/organisations`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        organisationsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdClosePostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Organisation>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}/close`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        organisationsOrganisationIdCloseTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Organisation>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        organisationsOrganisationIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: OrganisationsOrganisationIdPatch,
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Organisation>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        organisationsOrganisationIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdMembersGetFetch = ({
  pathParams,
  queryParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
  queryParams: {
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<OrganisationMembers>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}/members?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        organisationsOrganisationIdMembersTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdMembersLeavePostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Organisation>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}/members/leave`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        organisationsOrganisationIdMembersLeaveTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdMembersUserIdRolePatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: OrganisationsRolePatch,
  pathParams: {
    organisationId: string,
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<OrganisationMember>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}/members/${encodeURIComponent(pathParams.userId)}/role`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        organisationsOrganisationIdMembersUserIdRoleTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdPermissionsGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<OrganisationPermissions>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}/permissions`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        organisationsOrganisationIdPermissionsTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdProfileGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<OrganisationProfile>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}/profile`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        organisationsOrganisationIdProfileTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdProfilePatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: OrganisationsProfilePatch,
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<OrganisationProfile>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}/profile`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        organisationsOrganisationIdProfileTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const organisationsOrganisationIdRolesGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<OrganisationRoles>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/organisations/${encodeURIComponent(pathParams.organisationId)}/roles`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        organisationsOrganisationIdRolesTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
