/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  InviteRequestMetas,
  InviteRequestsPost,
  InviteRequest
} from '@/common/gen';

export const inviteRequestsPendingTag = (): string => {
  return `/invite-requests/pending`;
};

export const inviteRequestsTag = (): string => {
  return `/invite-requests`;
};

export const inviteRequestsInviteRequestIdAcceptTag = ({
  pathParams,
}:{
  pathParams: {
    inviteRequestId: string,
  },
}): string => {
  return `/invite-requests/${encodeURIComponent(pathParams.inviteRequestId)}/accept`;
};

export const inviteRequestsInviteRequestIdCancelTag = ({
  pathParams,
}:{
  pathParams: {
    inviteRequestId: string,
  },
}): string => {
  return `/invite-requests/${encodeURIComponent(pathParams.inviteRequestId)}/cancel`;
};

export const inviteRequestsInviteRequestIdRejectTag = ({
  pathParams,
}:{
  pathParams: {
    inviteRequestId: string,
  },
}): string => {
  return `/invite-requests/${encodeURIComponent(pathParams.inviteRequestId)}/reject`;
};
export const inviteRequestsPendingGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    matterId?: string,
    userId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<InviteRequestMetas>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/invite-requests/pending?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        inviteRequestsPendingTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const inviteRequestsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: InviteRequestsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<InviteRequest>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/invite-requests`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        inviteRequestsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const inviteRequestsInviteRequestIdAcceptPostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteRequestId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<InviteRequest>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/invite-requests/${encodeURIComponent(pathParams.inviteRequestId)}/accept`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        inviteRequestsInviteRequestIdAcceptTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const inviteRequestsInviteRequestIdCancelPostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteRequestId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<InviteRequest>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/invite-requests/${encodeURIComponent(pathParams.inviteRequestId)}/cancel`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        inviteRequestsInviteRequestIdCancelTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const inviteRequestsInviteRequestIdRejectPostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteRequestId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<InviteRequest>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/invite-requests/${encodeURIComponent(pathParams.inviteRequestId)}/reject`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        inviteRequestsInviteRequestIdRejectTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
