/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  TourPageEnum,
  TourPages,
  tourPagesGetFetch,
  TourPagesPost,
  TourPage,
  tourPagesPostFetch
} from '@/common/gen';

export const tourPagesGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    tourPage?: TourPageEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<TourPages>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await tourPagesGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<TourPages>({tag: 'tourPagesGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as TourPages, error: null };
};

export const tourPagesPost = async ({
  body,
  ...requestInitArgs
}:{
  body: TourPagesPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<TourPage>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await tourPagesPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<TourPage>({tag: 'tourPagesPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as TourPage, error: null };
};
