/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  Matters,
  mattersGetFetch,
  MattersPost,
  Matter,
  mattersPostFetch,
  MatterMeta,
  mattersMetaGetFetch,
  MatterStatusEnum,
  MatterOverviews,
  mattersOverviewGetFetch,
  mattersMatterIdClosePostFetch,
  MatterDetail,
  mattersMatterIdDetailGetFetch,
  MattersDetailPatch,
  mattersMatterIdDetailPatchFetch,
  mattersMatterIdGetFetch,
  MattersMatterIdPatch,
  mattersMatterIdPatchFetch,
  MatterMembers,
  mattersMatterIdMembersGetFetch,
  MattersRolePatch,
  MatterMember,
  mattersMatterIdMembersUserIdRolePatchFetch,
  MatterPermissions,
  mattersMatterIdPermissionsGetFetch,
  MatterSetting,
  mattersMatterIdSettingsGetFetch,
  MattersMatterSettingIdPatch,
  mattersMatterIdSettingsMatterSettingIdPatchFetch
} from '@/common/gen';

export const mattersGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    includeClosed?: boolean,
    matterId?: string,
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Matters>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Matters>({tag: 'mattersGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Matters, error: null };
};

export const mattersPost = async ({
  body,
  ...requestInitArgs
}:{
  body: MattersPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Matter>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Matter>({tag: 'mattersPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Matter, error: null };
};

export const mattersMetaGet = async (requestInitArgs: Omit<RequestInit, 'body'>): Promise<ActionResult<MatterMeta>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMetaGetFetch({
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterMeta>({tag: 'mattersMetaGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterMeta, error: null };
};

export const mattersOverviewGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    search?: string,
    matterStatuses?: MatterStatusEnum[],
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterOverviews>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersOverviewGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterOverviews>({tag: 'mattersOverviewGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterOverviews, error: null };
};

export const mattersMatterIdClosePost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Matter>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdClosePostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Matter>({tag: 'mattersMatterIdClosePost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Matter, error: null };
};

export const mattersMatterIdDetailGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterDetail>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdDetailGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterDetail>({tag: 'mattersMatterIdDetailGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterDetail, error: null };
};

export const mattersMatterIdDetailPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MattersDetailPatch,
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterDetail>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdDetailPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterDetail>({tag: 'mattersMatterIdDetailPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterDetail, error: null };
};

export const mattersMatterIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Matter>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Matter>({tag: 'mattersMatterIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Matter, error: null };
};

export const mattersMatterIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MattersMatterIdPatch,
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Matter>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Matter>({tag: 'mattersMatterIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Matter, error: null };
};

export const mattersMatterIdMembersGet = async ({
  pathParams,
  queryParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
  queryParams: {
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterMembers>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdMembersGetFetch({
    pathParams,
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterMembers>({tag: 'mattersMatterIdMembersGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterMembers, error: null };
};

export const mattersMatterIdMembersUserIdRolePatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MattersRolePatch,
  pathParams: {
    matterId: string,
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterMember>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdMembersUserIdRolePatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterMember>({tag: 'mattersMatterIdMembersUserIdRolePatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterMember, error: null };
};

export const mattersMatterIdPermissionsGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterPermissions>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdPermissionsGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterPermissions>({tag: 'mattersMatterIdPermissionsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterPermissions, error: null };
};

export const mattersMatterIdSettingsGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterSetting>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdSettingsGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterSetting>({tag: 'mattersMatterIdSettingsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterSetting, error: null };
};

export const mattersMatterIdSettingsMatterSettingIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MattersMatterSettingIdPatch,
  pathParams: {
    matterId: string,
    matterSettingId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterSetting>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await mattersMatterIdSettingsMatterSettingIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterSetting>({tag: 'mattersMatterIdSettingsMatterSettingIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterSetting, error: null };
};
