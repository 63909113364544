/*
* This file is auto generated - do not edit manually
*/
"use client"

import { Route } from "next";
import { useRouter } from "next/navigation";
import useSWR, { SWRConfiguration } from "swr";
import { SwrError } from ".";
import {
  TourPageEnum,
  TourPages,
  tourPagesGetFetch,
  tourPagesTag
} from '@/common/gen';

export const useTourPagesGet = ({
    queryParams,
  }:{
  queryParams: {
    limit?: number,
    offset?: number,
    tourPage?: TourPageEnum,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<TourPages>(
    tourPagesTag(),
    () => tourPagesGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}

