/*
* This file is auto generated - do not edit manually
*/
"use client"

import { Route } from "next";
import { useRouter } from "next/navigation";
import useSWR, { SWRConfiguration } from "swr";
import { SwrError } from ".";
import {
  Documents,
  documentsGetFetch,
  documentsTag,
  DocumentMeta,
  documentsMetaGetFetch,
  documentsMetaTag,
  DocumentOverviews,
  documentsOverviewGetFetch,
  documentsOverviewTag,
  Document,
  documentsDocumentIdGetFetch,
  documentsDocumentIdTag,
  DocumentUpload,
  documentsDocumentIdUploadGetFetch,
  documentsDocumentIdUploadTag
} from '@/common/gen';

export const useDocumentsGet = ({
    queryParams,
  }:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepId?: string,
    formFieldId?: string,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<Documents>(
    documentsTag(),
    () => documentsGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useDocumentsMetaGet = ({
    queryParams,
  }:{
  queryParams: {
    matterId?: string,
    matterStepId?: string,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<DocumentMeta>(
    documentsMetaTag(),
    () => documentsMetaGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useDocumentsOverviewGet = ({
    queryParams,
  }:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepId?: string,
    formFieldId?: string,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<DocumentOverviews>(
    documentsOverviewTag(),
    () => documentsOverviewGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useDocumentsDocumentIdGet = ({
    pathParams,
  }:{
  pathParams: {
    documentId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<Document>(
    documentsDocumentIdTag({ pathParams }),
    () => documentsDocumentIdGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useDocumentsDocumentIdUploadGet = ({
    pathParams,
  }:{
  pathParams: {
    documentId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<DocumentUpload>(
    documentsDocumentIdUploadTag({ pathParams }),
    () => documentsDocumentIdUploadGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}

