/*
* This file is auto generated - do not edit manually
*/
"use client"

import { Route } from "next";
import { useRouter } from "next/navigation";
import useSWR, { SWRConfiguration } from "swr";
import { SwrError } from ".";
import {
  MatterSteps,
  matterStepsGetFetch,
  matterStepsTag,
  MatterStepMeta,
  matterStepsMetaGetFetch,
  matterStepsMetaTag,
  MatterStepStatusEnum,
  MatterStepOverviews,
  matterStepsOverviewGetFetch,
  matterStepsOverviewTag,
  MatterStepFormResponses,
  matterStepsMatterStepIdFormResponsesGetFetch,
  matterStepsMatterStepIdFormResponsesTag,
  MatterStepFormResponse,
  matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdGetFetch,
  matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdTag,
  MatterStep,
  matterStepsMatterStepIdGetFetch,
  matterStepsMatterStepIdTag
} from '@/common/gen';

export const useMatterStepsGet = ({
    queryParams,
  }:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<MatterSteps>(
    matterStepsTag(),
    () => matterStepsGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useMatterStepsMetaGet = ({
    queryParams,
  }:{
  queryParams: {
    matterId?: string,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<MatterStepMeta>(
    matterStepsMetaTag(),
    () => matterStepsMetaGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useMatterStepsOverviewGet = ({
    queryParams,
  }:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepStatuses?: MatterStepStatusEnum[],
    assignedToMe?: boolean,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<MatterStepOverviews>(
    matterStepsOverviewTag(),
    () => matterStepsOverviewGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useMatterStepsMatterStepIdFormResponsesGet = ({
    pathParams,
    queryParams,
  }:{
  pathParams: {
    matterStepId: string,
  },
  queryParams: {
    limit?: number,
    offset?: number,
    latest?: boolean,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<MatterStepFormResponses>(
    matterStepsMatterStepIdFormResponsesTag({ pathParams }),
    () => matterStepsMatterStepIdFormResponsesGetFetch({pathParams, queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useMatterStepsMatterStepIdFormResponsesMatterStepFormResponseIdGet = ({
    pathParams,
  }:{
  pathParams: {
    matterStepId: string,
    matterStepFormResponseId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<MatterStepFormResponse>(
    matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdTag({ pathParams }),
    () => matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useMatterStepsMatterStepIdGet = ({
    pathParams,
  }:{
  pathParams: {
    matterStepId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<MatterStep>(
    matterStepsMatterStepIdTag({ pathParams }),
    () => matterStepsMatterStepIdGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}

