/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  Matters,
  MattersPost,
  Matter,
  MatterMeta,
  MatterStatusEnum,
  MatterOverviews,
  MatterDetail,
  MattersDetailPatch,
  MattersMatterIdPatch,
  MatterMembers,
  MattersRolePatch,
  MatterMember,
  MatterPermissions,
  MatterSetting,
  MattersMatterSettingIdPatch
} from '@/common/gen';

export const mattersTag = (): string => {
  return `/matters`;
};

export const mattersMetaTag = (): string => {
  return `/matters/meta`;
};

export const mattersOverviewTag = (): string => {
  return `/matters/overview`;
};

export const mattersMatterIdCloseTag = ({
  pathParams,
}:{
  pathParams: {
    matterId: string,
  },
}): string => {
  return `/matters/${encodeURIComponent(pathParams.matterId)}/close`;
};

export const mattersMatterIdDetailTag = ({
  pathParams,
}:{
  pathParams: {
    matterId: string,
  },
}): string => {
  return `/matters/${encodeURIComponent(pathParams.matterId)}/detail`;
};

export const mattersMatterIdTag = ({
  pathParams,
}:{
  pathParams: {
    matterId: string,
  },
}): string => {
  return `/matters/${encodeURIComponent(pathParams.matterId)}`;
};

export const mattersMatterIdMembersTag = ({
  pathParams,
}:{
  pathParams: {
    matterId: string,
  },
}): string => {
  return `/matters/${encodeURIComponent(pathParams.matterId)}/members`;
};

export const mattersMatterIdMembersUserIdRoleTag = ({
  pathParams,
}:{
  pathParams: {
    matterId: string,
    userId: string,
  },
}): string => {
  return `/matters/${encodeURIComponent(pathParams.matterId)}/members/${encodeURIComponent(pathParams.userId)}/role`;
};

export const mattersMatterIdPermissionsTag = ({
  pathParams,
}:{
  pathParams: {
    matterId: string,
  },
}): string => {
  return `/matters/${encodeURIComponent(pathParams.matterId)}/permissions`;
};

export const mattersMatterIdSettingsTag = ({
  pathParams,
}:{
  pathParams: {
    matterId: string,
  },
}): string => {
  return `/matters/${encodeURIComponent(pathParams.matterId)}/settings`;
};

export const mattersMatterIdSettingsMatterSettingIdTag = ({
  pathParams,
}:{
  pathParams: {
    matterId: string,
    matterSettingId: string,
  },
}): string => {
  return `/matters/${encodeURIComponent(pathParams.matterId)}/settings/${encodeURIComponent(pathParams.matterSettingId)}`;
};
export const mattersGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    includeClosed?: boolean,
    matterId?: string,
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Matters>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matters?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        mattersTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: MattersPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Matter>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matters`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        mattersTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMetaGetFetch = (requestInitArgs: Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterMeta>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matters/meta`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        mattersMetaTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersOverviewGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    search?: string,
    matterStatuses?: MatterStatusEnum[],
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterOverviews>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matters/overview?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        mattersOverviewTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdClosePostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Matter>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}/close`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        mattersMatterIdCloseTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdDetailGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterDetail>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}/detail`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        mattersMatterIdDetailTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdDetailPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MattersDetailPatch,
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterDetail>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}/detail`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        mattersMatterIdDetailTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Matter>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        mattersMatterIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MattersMatterIdPatch,
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Matter>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        mattersMatterIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdMembersGetFetch = ({
  pathParams,
  queryParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
  queryParams: {
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterMembers>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}/members?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        mattersMatterIdMembersTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdMembersUserIdRolePatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MattersRolePatch,
  pathParams: {
    matterId: string,
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterMember>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}/members/${encodeURIComponent(pathParams.userId)}/role`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        mattersMatterIdMembersUserIdRoleTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdPermissionsGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterPermissions>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}/permissions`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        mattersMatterIdPermissionsTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdSettingsGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterSetting>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}/settings`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        mattersMatterIdSettingsTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const mattersMatterIdSettingsMatterSettingIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MattersMatterSettingIdPatch,
  pathParams: {
    matterId: string,
    matterSettingId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterSetting>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matters/${encodeURIComponent(pathParams.matterId)}/settings/${encodeURIComponent(pathParams.matterSettingId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        mattersMatterIdSettingsMatterSettingIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
