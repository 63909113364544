/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  StepDocumentUploadsPost,
  StepDocumentUpload,
  stepDocumentUploadsPostFetch,
  stepDocumentUploadsStepDocumentUploadIdGetFetch,
  StepDocumentUploadsStepDocumentUploadIdPatch,
  stepDocumentUploadsStepDocumentUploadIdPatchFetch
} from '@/common/gen';

export const stepDocumentUploadsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: StepDocumentUploadsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepDocumentUpload>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepDocumentUploadsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepDocumentUpload>({tag: 'stepDocumentUploadsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepDocumentUpload, error: null };
};

export const stepDocumentUploadsStepDocumentUploadIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepDocumentUploadId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepDocumentUpload>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepDocumentUploadsStepDocumentUploadIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepDocumentUpload>({tag: 'stepDocumentUploadsStepDocumentUploadIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepDocumentUpload, error: null };
};

export const stepDocumentUploadsStepDocumentUploadIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: StepDocumentUploadsStepDocumentUploadIdPatch,
  pathParams: {
    stepDocumentUploadId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepDocumentUpload>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepDocumentUploadsStepDocumentUploadIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepDocumentUpload>({tag: 'stepDocumentUploadsStepDocumentUploadIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepDocumentUpload, error: null };
};
