/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  User,
  UsersUserIdPatch,
  UserProfile,
  UsersProfilePatch,
  UserSetting,
  UsersUserSettingIdPatch
} from '@/common/gen';

export const usersUserIdTag = ({
  pathParams,
}:{
  pathParams: {
    userId: string,
  },
}): string => {
  return `/users/${encodeURIComponent(pathParams.userId)}`;
};

export const usersUserIdProfileTag = ({
  pathParams,
}:{
  pathParams: {
    userId: string,
  },
}): string => {
  return `/users/${encodeURIComponent(pathParams.userId)}/profile`;
};

export const usersUserIdSettingsTag = ({
  pathParams,
}:{
  pathParams: {
    userId: string,
  },
}): string => {
  return `/users/${encodeURIComponent(pathParams.userId)}/settings`;
};

export const usersUserIdSettingsUserSettingIdTag = ({
  pathParams,
}:{
  pathParams: {
    userId: string,
    userSettingId: string,
  },
}): string => {
  return `/users/${encodeURIComponent(pathParams.userId)}/settings/${encodeURIComponent(pathParams.userSettingId)}`;
};
export const usersUserIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<User>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/users/${encodeURIComponent(pathParams.userId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        usersUserIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const usersUserIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: UsersUserIdPatch,
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<User>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/users/${encodeURIComponent(pathParams.userId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        usersUserIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const usersUserIdProfileGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<UserProfile>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/users/${encodeURIComponent(pathParams.userId)}/profile`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        usersUserIdProfileTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const usersUserIdProfilePatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: UsersProfilePatch,
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<UserProfile>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/users/${encodeURIComponent(pathParams.userId)}/profile`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        usersUserIdProfileTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const usersUserIdSettingsGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<UserSetting>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/users/${encodeURIComponent(pathParams.userId)}/settings`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        usersUserIdSettingsTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const usersUserIdSettingsUserSettingIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: UsersUserSettingIdPatch,
  pathParams: {
    userId: string,
    userSettingId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<UserSetting>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/users/${encodeURIComponent(pathParams.userId)}/settings/${encodeURIComponent(pathParams.userSettingId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        usersUserIdSettingsUserSettingIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
