/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  InviteMetas,
  invitesPendingGetFetch,
  InvitesPost,
  Invite,
  invitesPostFetch,
  invitesInviteIdAcceptPostFetch,
  invitesInviteIdCancelPostFetch,
  InviteMeta,
  invitesInviteIdMetaGetFetch,
  invitesInviteIdRejectPostFetch
} from '@/common/gen';

export const invitesPendingGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    matterId?: string,
    userId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<InviteMetas>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await invitesPendingGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<InviteMetas>({tag: 'invitesPendingGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as InviteMetas, error: null };
};

export const invitesPost = async ({
  body,
  ...requestInitArgs
}:{
  body: InvitesPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Invite>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await invitesPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Invite>({tag: 'invitesPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Invite, error: null };
};

export const invitesInviteIdAcceptPost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Invite>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await invitesInviteIdAcceptPostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Invite>({tag: 'invitesInviteIdAcceptPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Invite, error: null };
};

export const invitesInviteIdCancelPost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Invite>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await invitesInviteIdCancelPostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Invite>({tag: 'invitesInviteIdCancelPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Invite, error: null };
};

export const invitesInviteIdMetaGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<InviteMeta>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await invitesInviteIdMetaGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<InviteMeta>({tag: 'invitesInviteIdMetaGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as InviteMeta, error: null };
};

export const invitesInviteIdRejectPost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Invite>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await invitesInviteIdRejectPostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Invite>({tag: 'invitesInviteIdRejectPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Invite, error: null };
};
