/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  StepFormsPost,
  StepForm,
  StepFormsStepFormIdPatch
} from '@/common/gen';

export const stepFormsTag = (): string => {
  return `/step-forms`;
};

export const stepFormsStepFormIdTag = ({
  pathParams,
}:{
  pathParams: {
    stepFormId: string,
  },
}): string => {
  return `/step-forms/${encodeURIComponent(pathParams.stepFormId)}`;
};
export const stepFormsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: StepFormsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepForm>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/step-forms`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        stepFormsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepFormsStepFormIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepFormId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepForm>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/step-forms/${encodeURIComponent(pathParams.stepFormId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        stepFormsStepFormIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepFormsStepFormIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: StepFormsStepFormIdPatch,
  pathParams: {
    stepFormId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepForm>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/step-forms/${encodeURIComponent(pathParams.stepFormId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        stepFormsStepFormIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
