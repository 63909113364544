/*
* This file is auto generated - do not edit manually
*/
"use client"

import { Route } from "next";
import { useRouter } from "next/navigation";
import useSWR, { SWRConfiguration } from "swr";
import { SwrError } from ".";
import {
  SortDirectionEnum,
  EnquirySortByEnum,
  Enquiries,
  enquiriesGetFetch,
  enquiriesTag,
  EnquiryMeta,
  enquiriesMetaGetFetch,
  enquiriesMetaTag,
  EnquiryStatusEnum,
  EnquiryOverviews,
  enquiriesOverviewGetFetch,
  enquiriesOverviewTag,
  Enquiry,
  enquiriesEnquiryIdGetFetch,
  enquiriesEnquiryIdTag,
  EnquiryPermissions,
  enquiriesEnquiryIdPermissionsGetFetch,
  enquiriesEnquiryIdPermissionsTag
} from '@/common/gen';

export const useEnquiriesGet = ({
    queryParams,
  }:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
    sortDirection?: SortDirectionEnum,
    sortBy?: EnquirySortByEnum,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<Enquiries>(
    enquiriesTag(),
    () => enquiriesGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useEnquiriesMetaGet = ({
    queryParams,
  }:{
  queryParams: {
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<EnquiryMeta>(
    enquiriesMetaTag(),
    () => enquiriesMetaGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useEnquiriesOverviewGet = ({
    queryParams,
  }:{
  queryParams: {
    limit?: number,
    offset?: number,
    enquiryStatuses?: EnquiryStatusEnum[],
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
    sortBy?: EnquirySortByEnum,
    sortDirection?: SortDirectionEnum,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<EnquiryOverviews>(
    enquiriesOverviewTag(),
    () => enquiriesOverviewGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useEnquiriesEnquiryIdGet = ({
    pathParams,
  }:{
  pathParams: {
    enquiryId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<Enquiry>(
    enquiriesEnquiryIdTag({ pathParams }),
    () => enquiriesEnquiryIdGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useEnquiriesEnquiryIdPermissionsGet = ({
    pathParams,
  }:{
  pathParams: {
    enquiryId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<EnquiryPermissions>(
    enquiriesEnquiryIdPermissionsTag({ pathParams }),
    () => enquiriesEnquiryIdPermissionsGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}

