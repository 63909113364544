/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  FormTemplates,
  formTemplatesGetFetch,
  FormTemplatesPost,
  FormTemplate,
  formTemplatesPostFetch,
  FormTemplateMeta,
  formTemplatesMetaGetFetch,
  FormTemplateStatusEnum,
  FormTemplateOverviews,
  formTemplatesOverviewGetFetch,
  formTemplatesFormTemplateIdGetFetch,
  FormTemplatesFormTemplateIdPatch,
  formTemplatesFormTemplateIdPatchFetch,
  FormTemplatePermissions,
  formTemplatesFormTemplateIdPermissionsGetFetch
} from '@/common/gen';

export const formTemplatesGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplates>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplatesGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplates>({tag: 'formTemplatesGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplates, error: null };
};

export const formTemplatesPost = async ({
  body,
  ...requestInitArgs
}:{
  body: FormTemplatesPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplate>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplatesPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplate>({tag: 'formTemplatesPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplate, error: null };
};

export const formTemplatesMetaGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplateMeta>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplatesMetaGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplateMeta>({tag: 'formTemplatesMetaGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplateMeta, error: null };
};

export const formTemplatesOverviewGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    search?: string,
    formTemplateStatuses?: FormTemplateStatusEnum[],
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplateOverviews>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplatesOverviewGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplateOverviews>({tag: 'formTemplatesOverviewGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplateOverviews, error: null };
};

export const formTemplatesFormTemplateIdGet = async ({
  pathParams,
  queryParams,
  ...requestInitArgs
}:{
  pathParams: {
    formTemplateId: string,
  },
  queryParams: {
    matterStepId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplate>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplatesFormTemplateIdGetFetch({
    pathParams,
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplate>({tag: 'formTemplatesFormTemplateIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplate, error: null };
};

export const formTemplatesFormTemplateIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: FormTemplatesFormTemplateIdPatch,
  pathParams: {
    formTemplateId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplate>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplatesFormTemplateIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplate>({tag: 'formTemplatesFormTemplateIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplate, error: null };
};

export const formTemplatesFormTemplateIdPermissionsGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    formTemplateId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<FormTemplatePermissions>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await formTemplatesFormTemplateIdPermissionsGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<FormTemplatePermissions>({tag: 'formTemplatesFormTemplateIdPermissionsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as FormTemplatePermissions, error: null };
};
