/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  Avatars,
  AvatarsPost,
  Avatar,
  AvatarUpload
} from '@/common/gen';

export const avatarsTag = (): string => {
  return `/avatars`;
};

export const avatarsAvatarIdUploadCompleteTag = ({
  pathParams,
}:{
  pathParams: {
    avatarId: string,
  },
}): string => {
  return `/avatars/${encodeURIComponent(pathParams.avatarId)}/upload/complete`;
};

export const avatarsAvatarIdUploadTag = ({
  pathParams,
}:{
  pathParams: {
    avatarId: string,
  },
}): string => {
  return `/avatars/${encodeURIComponent(pathParams.avatarId)}/upload`;
};
export const avatarsGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Avatars>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/avatars?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        avatarsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const avatarsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: AvatarsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Avatar>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/avatars`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        avatarsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const avatarsAvatarIdUploadCompletePostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    avatarId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Avatar>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/avatars/${encodeURIComponent(pathParams.avatarId)}/upload/complete`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        avatarsAvatarIdUploadCompleteTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const avatarsAvatarIdUploadGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    avatarId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<AvatarUpload>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/avatars/${encodeURIComponent(pathParams.avatarId)}/upload`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        avatarsAvatarIdUploadTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
