/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  NotificationsDismissAllPost,
  Affected,
  notificationsDismissAllPostFetch,
  NotificationSortByEnum,
  SortDirectionEnum,
  NotificationMetas,
  notificationsGetFetch,
  Notification,
  notificationsNotificationIdDismissPostFetch
} from '@/common/gen';

export const notificationsDismissAllPost = async ({
  body,
  ...requestInitArgs
}:{
  body: NotificationsDismissAllPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Affected>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await notificationsDismissAllPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Affected>({tag: 'notificationsDismissAllPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Affected, error: null };
};

export const notificationsGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    dismissed?: boolean,
    sortBy?: NotificationSortByEnum,
    sortDirection?: SortDirectionEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<NotificationMetas>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await notificationsGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<NotificationMetas>({tag: 'notificationsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as NotificationMetas, error: null };
};

export const notificationsNotificationIdDismissPost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    notificationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Notification>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await notificationsNotificationIdDismissPostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Notification>({tag: 'notificationsNotificationIdDismissPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Notification, error: null };
};
