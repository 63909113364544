/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  Logos,
  logosGetFetch,
  LogosPost,
  Logo,
  logosPostFetch,
  logosLogoIdUploadCompletePostFetch,
  LogoUpload,
  logosLogoIdUploadGetFetch
} from '@/common/gen';

export const logosGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    organisationId?: string,
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Logos>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await logosGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Logos>({tag: 'logosGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Logos, error: null };
};

export const logosPost = async ({
  body,
  ...requestInitArgs
}:{
  body: LogosPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Logo>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await logosPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Logo>({tag: 'logosPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Logo, error: null };
};

export const logosLogoIdUploadCompletePost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    logoId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Logo>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await logosLogoIdUploadCompletePostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Logo>({tag: 'logosLogoIdUploadCompletePost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Logo, error: null };
};

export const logosLogoIdUploadGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    logoId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<LogoUpload>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await logosLogoIdUploadGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<LogoUpload>({tag: 'logosLogoIdUploadGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as LogoUpload, error: null };
};
