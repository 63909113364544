/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  FormTemplates,
  FormTemplatesPost,
  FormTemplate,
  FormTemplateMeta,
  FormTemplateStatusEnum,
  FormTemplateOverviews,
  FormTemplatesFormTemplateIdPatch,
  FormTemplatePermissions
} from '@/common/gen';

export const formTemplatesTag = (): string => {
  return `/form-templates`;
};

export const formTemplatesMetaTag = (): string => {
  return `/form-templates/meta`;
};

export const formTemplatesOverviewTag = (): string => {
  return `/form-templates/overview`;
};

export const formTemplatesFormTemplateIdTag = ({
  pathParams,
}:{
  pathParams: {
    formTemplateId: string,
  },
}): string => {
  return `/form-templates/${encodeURIComponent(pathParams.formTemplateId)}`;
};

export const formTemplatesFormTemplateIdPermissionsTag = ({
  pathParams,
}:{
  pathParams: {
    formTemplateId: string,
  },
}): string => {
  return `/form-templates/${encodeURIComponent(pathParams.formTemplateId)}/permissions`;
};
export const formTemplatesGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<FormTemplates>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/form-templates?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        formTemplatesTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const formTemplatesPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: FormTemplatesPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<FormTemplate>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/form-templates`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        formTemplatesTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const formTemplatesMetaGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<FormTemplateMeta>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/form-templates/meta?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        formTemplatesMetaTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const formTemplatesOverviewGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    search?: string,
    formTemplateStatuses?: FormTemplateStatusEnum[],
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<FormTemplateOverviews>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/form-templates/overview?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        formTemplatesOverviewTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const formTemplatesFormTemplateIdGetFetch = ({
  pathParams,
  queryParams,
  ...requestInitArgs
}:{
  pathParams: {
    formTemplateId: string,
  },
  queryParams: {
    matterStepId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<FormTemplate>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/form-templates/${encodeURIComponent(pathParams.formTemplateId)}?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        formTemplatesFormTemplateIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const formTemplatesFormTemplateIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: FormTemplatesFormTemplateIdPatch,
  pathParams: {
    formTemplateId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<FormTemplate>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/form-templates/${encodeURIComponent(pathParams.formTemplateId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        formTemplatesFormTemplateIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const formTemplatesFormTemplateIdPermissionsGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    formTemplateId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<FormTemplatePermissions>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/form-templates/${encodeURIComponent(pathParams.formTemplateId)}/permissions`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        formTemplatesFormTemplateIdPermissionsTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
