/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  NotificationsDismissAllPost,
  Affected,
  NotificationSortByEnum,
  SortDirectionEnum,
  NotificationMetas,
  Notification
} from '@/common/gen';

export const notificationsDismissAllTag = (): string => {
  return `/notifications/dismiss-all`;
};

export const notificationsTag = (): string => {
  return `/notifications`;
};

export const notificationsNotificationIdDismissTag = ({
  pathParams,
}:{
  pathParams: {
    notificationId: string,
  },
}): string => {
  return `/notifications/${encodeURIComponent(pathParams.notificationId)}/dismiss`;
};
export const notificationsDismissAllPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: NotificationsDismissAllPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Affected>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/notifications/dismiss-all`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        notificationsDismissAllTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const notificationsGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    dismissed?: boolean,
    sortBy?: NotificationSortByEnum,
    sortDirection?: SortDirectionEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<NotificationMetas>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/notifications?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        notificationsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const notificationsNotificationIdDismissPostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    notificationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Notification>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/notifications/${encodeURIComponent(pathParams.notificationId)}/dismiss`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        notificationsNotificationIdDismissTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
