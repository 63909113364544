/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  Workflows,
  WorkflowsPost,
  Workflow,
  WorkflowMeta,
  WorkflowStatusEnum,
  WorkflowOverviews,
  WorkflowsWorkflowIdPatch,
  WorkflowPermissions
} from '@/common/gen';

export const workflowsTag = (): string => {
  return `/workflows`;
};

export const workflowsMetaTag = (): string => {
  return `/workflows/meta`;
};

export const workflowsOverviewTag = (): string => {
  return `/workflows/overview`;
};

export const workflowsWorkflowIdTag = ({
  pathParams,
}:{
  pathParams: {
    workflowId: string,
  },
}): string => {
  return `/workflows/${encodeURIComponent(pathParams.workflowId)}`;
};

export const workflowsWorkflowIdPermissionsTag = ({
  pathParams,
}:{
  pathParams: {
    workflowId: string,
  },
}): string => {
  return `/workflows/${encodeURIComponent(pathParams.workflowId)}/permissions`;
};
export const workflowsGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Workflows>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/workflows?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        workflowsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: WorkflowsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Workflow>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/workflows`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        workflowsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowsMetaGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<WorkflowMeta>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/workflows/meta?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        workflowsMetaTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowsOverviewGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    search?: string,
    workflowStatuses?: WorkflowStatusEnum[],
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<WorkflowOverviews>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/workflows/overview?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        workflowsOverviewTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowsWorkflowIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    workflowId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Workflow>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/workflows/${encodeURIComponent(pathParams.workflowId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        workflowsWorkflowIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowsWorkflowIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: WorkflowsWorkflowIdPatch,
  pathParams: {
    workflowId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Workflow>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/workflows/${encodeURIComponent(pathParams.workflowId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        workflowsWorkflowIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowsWorkflowIdPermissionsGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    workflowId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<WorkflowPermissions>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/workflows/${encodeURIComponent(pathParams.workflowId)}/permissions`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        workflowsWorkflowIdPermissionsTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
