/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  ResourceEnum,
  ActivitySortByEnum,
  SortDirectionEnum,
  ActivityMetas,
  activitiesGetFetch
} from '@/common/gen';

export const activitiesGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    activityResource?: ResourceEnum,
    organisationId?: string,
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
    enquiryId?: string,
    sortBy?: ActivitySortByEnum,
    sortDirection?: SortDirectionEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<ActivityMetas>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await activitiesGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<ActivityMetas>({tag: 'activitiesGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as ActivityMetas, error: null };
};
