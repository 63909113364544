/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  Messages,
  MessagesPost,
  Message
} from '@/common/gen';

export const messagesTag = (): string => {
  return `/messages`;
};

export const messagesMessageIdTag = ({
  pathParams,
}:{
  pathParams: {
    messageId: string,
  },
}): string => {
  return `/messages/${encodeURIComponent(pathParams.messageId)}`;
};
export const messagesGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    enquiryId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Messages>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/messages?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        messagesTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const messagesPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: MessagesPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Message>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/messages`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        messagesTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const messagesMessageIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    messageId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Message>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/messages/${encodeURIComponent(pathParams.messageId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        messagesMessageIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
