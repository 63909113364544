/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  Currencies,
  currenciesGetFetch,
  Currency,
  currenciesCurrencyIdGetFetch
} from '@/common/gen';

export const currenciesGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    currencyCode?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Currencies>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await currenciesGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Currencies>({tag: 'currenciesGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Currencies, error: null };
};

export const currenciesCurrencyIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    currencyId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Currency>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await currenciesCurrencyIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Currency>({tag: 'currenciesCurrencyIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Currency, error: null };
};
