export enum MatterPermissionsEnum {
  CanClose = 'can_close',
  CanCreateDocument = 'can_create_document',
  CanCreateEnquiries = 'can_create_enquiries',
  CanEdit = 'can_edit',
  CanEditSteps = 'can_edit_steps',
  CanManageEnquiries = 'can_manage_enquiries',
  CanManageInvites = 'can_manage_invites',
  CanManageUsers = 'can_manage_users',
  CanUpdateStepsAssigned = 'can_update_steps_assigned',
  CanUpdateStepsStatus = 'can_update_steps_status',
  CanView = 'can_view',
  CanViewDocuments = 'can_view_documents',
  CanViewEnquiries = 'can_view_enquiries',
  CanViewMembers = 'can_view_members',
  CanViewSteps = 'can_view_steps',
  Member = 'member',
  Organisation = 'organisation',
  Owner = 'owner',
};
