/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  StepTasksPost,
  StepTask,
  stepTasksPostFetch,
  stepTasksStepTaskIdGetFetch,
  StepTasksStepTaskIdPatch,
  stepTasksStepTaskIdPatchFetch
} from '@/common/gen';

export const stepTasksPost = async ({
  body,
  ...requestInitArgs
}:{
  body: StepTasksPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepTask>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepTasksPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepTask>({tag: 'stepTasksPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepTask, error: null };
};

export const stepTasksStepTaskIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepTaskId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepTask>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepTasksStepTaskIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepTask>({tag: 'stepTasksStepTaskIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepTask, error: null };
};

export const stepTasksStepTaskIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: StepTasksStepTaskIdPatch,
  pathParams: {
    stepTaskId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<StepTask>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await stepTasksStepTaskIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<StepTask>({tag: 'stepTasksStepTaskIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as StepTask, error: null };
};
