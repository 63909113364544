/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';

export const healthTag = (): string => {
  return `/health`;
};
export const healthGetFetch = (requestInitArgs: Omit<RequestInit, 'body'>): Promise<HttpResponseType<unknown>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/health`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        healthTag()
      ],
    },
    ...requestInitArgsRest
  });

};
