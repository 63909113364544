export enum ActivityActionEnum {
  AddedMatterSteps = 'added_matter_steps',
  AssignedMatterStep = 'assigned_matter_step',
  ClosedMatter = 'closed_matter',
  ClosedOrganisation = 'closed_organisation',
  CreatedEnquiry = 'created_enquiry',
  CreatedMatter = 'created_matter',
  JoinedMatter = 'joined_matter',
  JoinedOrganisation = 'joined_organisation',
  RepliedToEnquiry = 'replied_to_enquiry',
  SubmittedMatterStepForm = 'submitted_matter_step_form',
  UpdatedEnquiryStatus = 'updated_enquiry_status',
  UpdatedMatterStepStatus = 'updated_matter_step_status',
  UploadedDocument = 'uploaded_document',
};
