/*
* This file is auto generated - do not edit manually
*/
"use client"

import { Route } from "next";
import { useRouter } from "next/navigation";
import useSWR, { SWRConfiguration } from "swr";
import { SwrError } from ".";
import {
  authCallbackGetFetch,
  authCallbackTag,
  authSignInGetFetch,
  authSignInTag,
  authSignOutGetFetch,
  authSignOutTag,
  UserInfo,
  authUserInfoGetFetch,
  authUserInfoTag
} from '@/common/gen';

export const useAuthCallbackGet = (
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<void>(
    authCallbackTag(),
    () => authCallbackGetFetch({...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useAuthSignInGet = ({
    queryParams,
  }:{
  queryParams: {
    redirectURI?: string,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<void>(
    authSignInTag(),
    () => authSignInGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useAuthSignOutGet = (
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<void>(
    authSignOutTag(),
    () => authSignOutGetFetch({...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useAuthUserInfoGet = (
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<UserInfo>(
    authUserInfoTag(),
    () => authUserInfoGetFetch({...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}

