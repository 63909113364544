export enum OrganisationPermissionsEnum {
  CanClose = 'can_close',
  CanCreateFormTemplates = 'can_create_form_templates',
  CanCreateMatter = 'can_create_matter',
  CanCreateSteps = 'can_create_steps',
  CanCreateWorkflows = 'can_create_workflows',
  CanEdit = 'can_edit',
  CanEditFormTemplates = 'can_edit_form_templates',
  CanEditSteps = 'can_edit_steps',
  CanEditWorkflows = 'can_edit_workflows',
  CanManageInvites = 'can_manage_invites',
  CanManageUsers = 'can_manage_users',
  CanViewFormTemplates = 'can_view_form_templates',
  CanViewPrivate = 'can_view_private',
  CanViewPublic = 'can_view_public',
  CanViewSteps = 'can_view_steps',
  CanViewWorkflows = 'can_view_workflows',
};
