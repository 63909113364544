/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  InviteMetas,
  InvitesPost,
  Invite,
  InviteMeta
} from '@/common/gen';

export const invitesPendingTag = (): string => {
  return `/invites/pending`;
};

export const invitesTag = (): string => {
  return `/invites`;
};

export const invitesInviteIdAcceptTag = ({
  pathParams,
}:{
  pathParams: {
    inviteId: string,
  },
}): string => {
  return `/invites/${encodeURIComponent(pathParams.inviteId)}/accept`;
};

export const invitesInviteIdCancelTag = ({
  pathParams,
}:{
  pathParams: {
    inviteId: string,
  },
}): string => {
  return `/invites/${encodeURIComponent(pathParams.inviteId)}/cancel`;
};

export const invitesInviteIdMetaTag = ({
  pathParams,
}:{
  pathParams: {
    inviteId: string,
  },
}): string => {
  return `/invites/${encodeURIComponent(pathParams.inviteId)}/meta`;
};

export const invitesInviteIdRejectTag = ({
  pathParams,
}:{
  pathParams: {
    inviteId: string,
  },
}): string => {
  return `/invites/${encodeURIComponent(pathParams.inviteId)}/reject`;
};
export const invitesPendingGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    matterId?: string,
    userId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<InviteMetas>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/invites/pending?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        invitesPendingTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const invitesPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: InvitesPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Invite>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/invites`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        invitesTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const invitesInviteIdAcceptPostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Invite>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/invites/${encodeURIComponent(pathParams.inviteId)}/accept`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        invitesInviteIdAcceptTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const invitesInviteIdCancelPostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Invite>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/invites/${encodeURIComponent(pathParams.inviteId)}/cancel`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        invitesInviteIdCancelTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const invitesInviteIdMetaGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<InviteMeta>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/invites/${encodeURIComponent(pathParams.inviteId)}/meta`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        invitesInviteIdMetaTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const invitesInviteIdRejectPostFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Invite>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/invites/${encodeURIComponent(pathParams.inviteId)}/reject`, {
    headers,
    method: 'POST',
    next: {
      tags: [
        invitesInviteIdRejectTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
