/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  OrganisationPermissionsEnum,
  Organisations,
  organisationsGetFetch,
  OrganisationsPost,
  Organisation,
  organisationsPostFetch,
  organisationsOrganisationIdClosePostFetch,
  organisationsOrganisationIdGetFetch,
  OrganisationsOrganisationIdPatch,
  organisationsOrganisationIdPatchFetch,
  OrganisationMembers,
  organisationsOrganisationIdMembersGetFetch,
  organisationsOrganisationIdMembersLeavePostFetch,
  OrganisationsRolePatch,
  OrganisationMember,
  organisationsOrganisationIdMembersUserIdRolePatchFetch,
  OrganisationPermissions,
  organisationsOrganisationIdPermissionsGetFetch,
  OrganisationProfile,
  organisationsOrganisationIdProfileGetFetch,
  OrganisationsProfilePatch,
  organisationsOrganisationIdProfilePatchFetch,
  OrganisationRoles,
  organisationsOrganisationIdRolesGetFetch
} from '@/common/gen';

export const organisationsGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationPermission?: OrganisationPermissionsEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Organisations>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Organisations>({tag: 'organisationsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Organisations, error: null };
};

export const organisationsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: OrganisationsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Organisation>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Organisation>({tag: 'organisationsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Organisation, error: null };
};

export const organisationsOrganisationIdClosePost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Organisation>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdClosePostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Organisation>({tag: 'organisationsOrganisationIdClosePost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Organisation, error: null };
};

export const organisationsOrganisationIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Organisation>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Organisation>({tag: 'organisationsOrganisationIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Organisation, error: null };
};

export const organisationsOrganisationIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: OrganisationsOrganisationIdPatch,
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Organisation>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Organisation>({tag: 'organisationsOrganisationIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Organisation, error: null };
};

export const organisationsOrganisationIdMembersGet = async ({
  pathParams,
  queryParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
  queryParams: {
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<OrganisationMembers>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdMembersGetFetch({
    pathParams,
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<OrganisationMembers>({tag: 'organisationsOrganisationIdMembersGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as OrganisationMembers, error: null };
};

export const organisationsOrganisationIdMembersLeavePost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Organisation>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdMembersLeavePostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Organisation>({tag: 'organisationsOrganisationIdMembersLeavePost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Organisation, error: null };
};

export const organisationsOrganisationIdMembersUserIdRolePatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: OrganisationsRolePatch,
  pathParams: {
    organisationId: string,
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<OrganisationMember>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdMembersUserIdRolePatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<OrganisationMember>({tag: 'organisationsOrganisationIdMembersUserIdRolePatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as OrganisationMember, error: null };
};

export const organisationsOrganisationIdPermissionsGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<OrganisationPermissions>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdPermissionsGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<OrganisationPermissions>({tag: 'organisationsOrganisationIdPermissionsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as OrganisationPermissions, error: null };
};

export const organisationsOrganisationIdProfileGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<OrganisationProfile>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdProfileGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<OrganisationProfile>({tag: 'organisationsOrganisationIdProfileGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as OrganisationProfile, error: null };
};

export const organisationsOrganisationIdProfilePatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: OrganisationsProfilePatch,
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<OrganisationProfile>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdProfilePatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<OrganisationProfile>({tag: 'organisationsOrganisationIdProfilePatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as OrganisationProfile, error: null };
};

export const organisationsOrganisationIdRolesGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    organisationId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<OrganisationRoles>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await organisationsOrganisationIdRolesGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<OrganisationRoles>({tag: 'organisationsOrganisationIdRolesGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as OrganisationRoles, error: null };
};
