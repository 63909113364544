/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  User,
  usersUserIdGetFetch,
  UsersUserIdPatch,
  usersUserIdPatchFetch,
  UserProfile,
  usersUserIdProfileGetFetch,
  UsersProfilePatch,
  usersUserIdProfilePatchFetch,
  UserSetting,
  usersUserIdSettingsGetFetch,
  UsersUserSettingIdPatch,
  usersUserIdSettingsUserSettingIdPatchFetch
} from '@/common/gen';

export const usersUserIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<User>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await usersUserIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<User>({tag: 'usersUserIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as User, error: null };
};

export const usersUserIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: UsersUserIdPatch,
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<User>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await usersUserIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<User>({tag: 'usersUserIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as User, error: null };
};

export const usersUserIdProfileGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<UserProfile>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await usersUserIdProfileGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<UserProfile>({tag: 'usersUserIdProfileGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as UserProfile, error: null };
};

export const usersUserIdProfilePatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: UsersProfilePatch,
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<UserProfile>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await usersUserIdProfilePatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<UserProfile>({tag: 'usersUserIdProfilePatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as UserProfile, error: null };
};

export const usersUserIdSettingsGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    userId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<UserSetting>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await usersUserIdSettingsGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<UserSetting>({tag: 'usersUserIdSettingsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as UserSetting, error: null };
};

export const usersUserIdSettingsUserSettingIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: UsersUserSettingIdPatch,
  pathParams: {
    userId: string,
    userSettingId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<UserSetting>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await usersUserIdSettingsUserSettingIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<UserSetting>({tag: 'usersUserIdSettingsUserSettingIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as UserSetting, error: null };
};
