/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  StepDocumentUploadsPost,
  StepDocumentUpload,
  StepDocumentUploadsStepDocumentUploadIdPatch
} from '@/common/gen';

export const stepDocumentUploadsTag = (): string => {
  return `/step-document-uploads`;
};

export const stepDocumentUploadsStepDocumentUploadIdTag = ({
  pathParams,
}:{
  pathParams: {
    stepDocumentUploadId: string,
  },
}): string => {
  return `/step-document-uploads/${encodeURIComponent(pathParams.stepDocumentUploadId)}`;
};
export const stepDocumentUploadsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: StepDocumentUploadsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepDocumentUpload>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/step-document-uploads`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        stepDocumentUploadsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepDocumentUploadsStepDocumentUploadIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepDocumentUploadId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepDocumentUpload>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/step-document-uploads/${encodeURIComponent(pathParams.stepDocumentUploadId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        stepDocumentUploadsStepDocumentUploadIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepDocumentUploadsStepDocumentUploadIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: StepDocumentUploadsStepDocumentUploadIdPatch,
  pathParams: {
    stepDocumentUploadId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepDocumentUpload>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/step-document-uploads/${encodeURIComponent(pathParams.stepDocumentUploadId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        stepDocumentUploadsStepDocumentUploadIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
