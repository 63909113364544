/*
* This file is auto generated - do not edit manually.
*/
export type HttpResponseType<T> = Response & {
  json: () => Promise<T>
};

export const encodeQueryParameters = (queryParameters: string | Record<string, any>): string => {
  return new URLSearchParams(queryParameters).toString();
};

export * from './ActivitiesFetch';
export * from './AuthFetch';
export * from './AvatarsFetch';
export * from './CurrenciesFetch';
export * from './DocumentsFetch';
export * from './EnquiriesFetch';
export * from './Form-template-contentsFetch';
export * from './Form-templatesFetch';
export * from './HealthFetch';
export * from './Invite-requestsFetch';
export * from './InvitesFetch';
export * from './LogosFetch';
export * from './Matter-stepsFetch';
export * from './MattersFetch';
export * from './MessagesFetch';
export * from './NotificationsFetch';
export * from './OrganisationsFetch';
export * from './Step-document-uploadsFetch';
export * from './Step-formsFetch';
export * from './Step-tasksFetch';
export * from './StepsFetch';
export * from './Tour-pagesFetch';
export * from './UsersFetch';
export * from './Workflow-stepsFetch';
export * from './WorkflowsFetch';