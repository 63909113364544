/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  TourPageEnum,
  TourPages,
  TourPagesPost,
  TourPage
} from '@/common/gen';

export const tourPagesTag = (): string => {
  return `/tour-pages`;
};
export const tourPagesGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    tourPage?: TourPageEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<TourPages>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/tour-pages?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        tourPagesTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const tourPagesPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: TourPagesPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<TourPage>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/tour-pages`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        tourPagesTag()
      ],
    },
    ...requestInitArgsRest
  });

};
