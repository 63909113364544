/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  Messages,
  messagesGetFetch,
  MessagesPost,
  Message,
  messagesPostFetch,
  messagesMessageIdGetFetch
} from '@/common/gen';

export const messagesGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    enquiryId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Messages>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await messagesGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Messages>({tag: 'messagesGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Messages, error: null };
};

export const messagesPost = async ({
  body,
  ...requestInitArgs
}:{
  body: MessagesPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Message>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await messagesPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Message>({tag: 'messagesPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Message, error: null };
};

export const messagesMessageIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    messageId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Message>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await messagesMessageIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Message>({tag: 'messagesMessageIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Message, error: null };
};
