/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  Documents,
  documentsGetFetch,
  DocumentsPost,
  Document,
  documentsPostFetch,
  DocumentMeta,
  documentsMetaGetFetch,
  DocumentOverviews,
  documentsOverviewGetFetch,
  documentsDocumentIdGetFetch,
  DocumentUpload,
  documentsDocumentIdUploadGetFetch
} from '@/common/gen';

export const documentsGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepId?: string,
    formFieldId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Documents>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await documentsGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Documents>({tag: 'documentsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Documents, error: null };
};

export const documentsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: DocumentsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Document>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await documentsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Document>({tag: 'documentsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Document, error: null };
};

export const documentsMetaGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    matterId?: string,
    matterStepId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<DocumentMeta>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await documentsMetaGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<DocumentMeta>({tag: 'documentsMetaGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as DocumentMeta, error: null };
};

export const documentsOverviewGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepId?: string,
    formFieldId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<DocumentOverviews>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await documentsOverviewGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<DocumentOverviews>({tag: 'documentsOverviewGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as DocumentOverviews, error: null };
};

export const documentsDocumentIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    documentId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Document>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await documentsDocumentIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Document>({tag: 'documentsDocumentIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Document, error: null };
};

export const documentsDocumentIdUploadGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    documentId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<DocumentUpload>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await documentsDocumentIdUploadGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<DocumentUpload>({tag: 'documentsDocumentIdUploadGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as DocumentUpload, error: null };
};
