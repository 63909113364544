/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  Avatars,
  avatarsGetFetch,
  AvatarsPost,
  Avatar,
  avatarsPostFetch,
  avatarsAvatarIdUploadCompletePostFetch,
  AvatarUpload,
  avatarsAvatarIdUploadGetFetch
} from '@/common/gen';

export const avatarsGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Avatars>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await avatarsGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Avatars>({tag: 'avatarsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Avatars, error: null };
};

export const avatarsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: AvatarsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Avatar>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await avatarsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Avatar>({tag: 'avatarsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Avatar, error: null };
};

export const avatarsAvatarIdUploadCompletePost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    avatarId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Avatar>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await avatarsAvatarIdUploadCompletePostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Avatar>({tag: 'avatarsAvatarIdUploadCompletePost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Avatar, error: null };
};

export const avatarsAvatarIdUploadGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    avatarId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<AvatarUpload>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await avatarsAvatarIdUploadGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<AvatarUpload>({tag: 'avatarsAvatarIdUploadGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as AvatarUpload, error: null };
};
