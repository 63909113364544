/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  authCallbackGetFetch,
  authSignInGetFetch,
  authSignOutGetFetch,
  AuthSignUpPost,
  User,
  authSignUpPostFetch,
  UserInfo,
  authUserInfoGetFetch
} from '@/common/gen';

export const authCallbackGet = async (requestInitArgs: Omit<RequestInit, 'body'>): Promise<ActionResult<void>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await authCallbackGetFetch({
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<void>({tag: 'authCallbackGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as void, error: null };
};

export const authSignInGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    redirectURI?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<void>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await authSignInGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<void>({tag: 'authSignInGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as void, error: null };
};

export const authSignOutGet = async (requestInitArgs: Omit<RequestInit, 'body'>): Promise<ActionResult<void>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await authSignOutGetFetch({
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<void>({tag: 'authSignOutGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as void, error: null };
};

export const authSignUpPost = async ({
  body,
  ...requestInitArgs
}:{
  body: AuthSignUpPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<User>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await authSignUpPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<User>({tag: 'authSignUpPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as User, error: null };
};

export const authUserInfoGet = async (requestInitArgs: Omit<RequestInit, 'body'>): Promise<ActionResult<UserInfo>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await authUserInfoGetFetch({
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<UserInfo>({tag: 'authUserInfoGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as UserInfo, error: null };
};
