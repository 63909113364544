/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  MatterStepsApplyWorkflowPost,
  MatterWorkflowApplyRes,
  matterStepsApplyWorkflowPostFetch,
  MatterStepsBulkUpdatePatch,
  MatterStepBulkUpdateRes,
  matterStepsBulkUpdatePatchFetch,
  MatterSteps,
  matterStepsGetFetch,
  MatterStepsPost,
  MatterStep,
  matterStepsPostFetch,
  MatterStepMeta,
  matterStepsMetaGetFetch,
  MatterStepStatusEnum,
  MatterStepOverviews,
  matterStepsOverviewGetFetch,
  MatterStepsAssignedPatch,
  matterStepsMatterStepIdAssignedPatchFetch,
  MatterStepFormResponses,
  matterStepsMatterStepIdFormResponsesGetFetch,
  MatterStepsFormResponsesPost,
  MatterStepFormResponse,
  matterStepsMatterStepIdFormResponsesPostFetch,
  matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdGetFetch,
  matterStepsMatterStepIdGetFetch,
  MatterStepsMatterStepIdPatch,
  matterStepsMatterStepIdPatchFetch,
  MatterStepsStatusPatch,
  matterStepsMatterStepIdStatusPatchFetch
} from '@/common/gen';

export const matterStepsApplyWorkflowPost = async ({
  body,
  ...requestInitArgs
}:{
  body: MatterStepsApplyWorkflowPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterWorkflowApplyRes>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsApplyWorkflowPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterWorkflowApplyRes>({tag: 'matterStepsApplyWorkflowPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterWorkflowApplyRes, error: null };
};

export const matterStepsBulkUpdatePatch = async ({
  body,
  ...requestInitArgs
}:{
  body: MatterStepsBulkUpdatePatch,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStepBulkUpdateRes>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsBulkUpdatePatchFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStepBulkUpdateRes>({tag: 'matterStepsBulkUpdatePatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStepBulkUpdateRes, error: null };
};

export const matterStepsGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterSteps>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterSteps>({tag: 'matterStepsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterSteps, error: null };
};

export const matterStepsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: MatterStepsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStep>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStep>({tag: 'matterStepsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStep, error: null };
};

export const matterStepsMetaGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStepMeta>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsMetaGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStepMeta>({tag: 'matterStepsMetaGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStepMeta, error: null };
};

export const matterStepsOverviewGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepStatuses?: MatterStepStatusEnum[],
    assignedToMe?: boolean,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStepOverviews>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsOverviewGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStepOverviews>({tag: 'matterStepsOverviewGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStepOverviews, error: null };
};

export const matterStepsMatterStepIdAssignedPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MatterStepsAssignedPatch,
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStep>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsMatterStepIdAssignedPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStep>({tag: 'matterStepsMatterStepIdAssignedPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStep, error: null };
};

export const matterStepsMatterStepIdFormResponsesGet = async ({
  pathParams,
  queryParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterStepId: string,
  },
  queryParams: {
    limit?: number,
    offset?: number,
    latest?: boolean,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStepFormResponses>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsMatterStepIdFormResponsesGetFetch({
    pathParams,
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStepFormResponses>({tag: 'matterStepsMatterStepIdFormResponsesGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStepFormResponses, error: null };
};

export const matterStepsMatterStepIdFormResponsesPost = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MatterStepsFormResponsesPost,
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStepFormResponse>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsMatterStepIdFormResponsesPostFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStepFormResponse>({tag: 'matterStepsMatterStepIdFormResponsesPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStepFormResponse, error: null };
};

export const matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterStepId: string,
    matterStepFormResponseId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStepFormResponse>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStepFormResponse>({tag: 'matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStepFormResponse, error: null };
};

export const matterStepsMatterStepIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStep>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsMatterStepIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStep>({tag: 'matterStepsMatterStepIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStep, error: null };
};

export const matterStepsMatterStepIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MatterStepsMatterStepIdPatch,
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStep>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsMatterStepIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStep>({tag: 'matterStepsMatterStepIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStep, error: null };
};

export const matterStepsMatterStepIdStatusPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MatterStepsStatusPatch,
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<MatterStep>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await matterStepsMatterStepIdStatusPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<MatterStep>({tag: 'matterStepsMatterStepIdStatusPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as MatterStep, error: null };
};
