/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  SortDirectionEnum,
  EnquirySortByEnum,
  Enquiries,
  EnquiriesPost,
  Enquiry,
  EnquiryMeta,
  EnquiryStatusEnum,
  EnquiryOverviews,
  EnquiriesEnquiryIdPatch,
  EnquiryPermissions,
  EnquiriesStatusPatch
} from '@/common/gen';

export const enquiriesTag = (): string => {
  return `/enquiries`;
};

export const enquiriesMetaTag = (): string => {
  return `/enquiries/meta`;
};

export const enquiriesOverviewTag = (): string => {
  return `/enquiries/overview`;
};

export const enquiriesEnquiryIdTag = ({
  pathParams,
}:{
  pathParams: {
    enquiryId: string,
  },
}): string => {
  return `/enquiries/${encodeURIComponent(pathParams.enquiryId)}`;
};

export const enquiriesEnquiryIdPermissionsTag = ({
  pathParams,
}:{
  pathParams: {
    enquiryId: string,
  },
}): string => {
  return `/enquiries/${encodeURIComponent(pathParams.enquiryId)}/permissions`;
};

export const enquiriesEnquiryIdStatusTag = ({
  pathParams,
}:{
  pathParams: {
    enquiryId: string,
  },
}): string => {
  return `/enquiries/${encodeURIComponent(pathParams.enquiryId)}/status`;
};
export const enquiriesGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
    sortDirection?: SortDirectionEnum,
    sortBy?: EnquirySortByEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Enquiries>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/enquiries?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        enquiriesTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const enquiriesPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: EnquiriesPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Enquiry>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/enquiries`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        enquiriesTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const enquiriesMetaGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<EnquiryMeta>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/enquiries/meta?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        enquiriesMetaTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const enquiriesOverviewGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    enquiryStatuses?: EnquiryStatusEnum[],
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
    sortBy?: EnquirySortByEnum,
    sortDirection?: SortDirectionEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<EnquiryOverviews>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/enquiries/overview?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        enquiriesOverviewTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const enquiriesEnquiryIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    enquiryId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Enquiry>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/enquiries/${encodeURIComponent(pathParams.enquiryId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        enquiriesEnquiryIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const enquiriesEnquiryIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: EnquiriesEnquiryIdPatch,
  pathParams: {
    enquiryId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Enquiry>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/enquiries/${encodeURIComponent(pathParams.enquiryId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        enquiriesEnquiryIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const enquiriesEnquiryIdPermissionsGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    enquiryId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<EnquiryPermissions>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/enquiries/${encodeURIComponent(pathParams.enquiryId)}/permissions`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        enquiriesEnquiryIdPermissionsTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const enquiriesEnquiryIdStatusPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: EnquiriesStatusPatch,
  pathParams: {
    enquiryId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Enquiry>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/enquiries/${encodeURIComponent(pathParams.enquiryId)}/status`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        enquiriesEnquiryIdStatusTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
