/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  StepStatusEnum,
  Steps,
  StepsPost,
  Step,
  StepMeta,
  StepOverviews,
  StepsStepIdPatch,
  StepPermissions
} from '@/common/gen';

export const stepsTag = (): string => {
  return `/steps`;
};

export const stepsMetaTag = (): string => {
  return `/steps/meta`;
};

export const stepsOverviewTag = (): string => {
  return `/steps/overview`;
};

export const stepsStepIdTag = ({
  pathParams,
}:{
  pathParams: {
    stepId: string,
  },
}): string => {
  return `/steps/${encodeURIComponent(pathParams.stepId)}`;
};

export const stepsStepIdPermissionsTag = ({
  pathParams,
}:{
  pathParams: {
    stepId: string,
  },
}): string => {
  return `/steps/${encodeURIComponent(pathParams.stepId)}/permissions`;
};
export const stepsGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    search?: string,
    stepStatuses?: StepStatusEnum[],
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Steps>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/steps?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        stepsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: StepsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Step>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/steps`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        stepsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepsMetaGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepMeta>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/steps/meta?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        stepsMetaTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepsOverviewGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    search?: string,
    stepStatuses?: StepStatusEnum[],
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepOverviews>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/steps/overview?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        stepsOverviewTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepsStepIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Step>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/steps/${encodeURIComponent(pathParams.stepId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        stepsStepIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepsStepIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: StepsStepIdPatch,
  pathParams: {
    stepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Step>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/steps/${encodeURIComponent(pathParams.stepId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        stepsStepIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepsStepIdPermissionsGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepPermissions>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/steps/${encodeURIComponent(pathParams.stepId)}/permissions`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        stepsStepIdPermissionsTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
