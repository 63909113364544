/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  ResourceEnum,
  ActivitySortByEnum,
  SortDirectionEnum,
  ActivityMetas
} from '@/common/gen';

export const activitiesTag = (): string => {
  return `/activities`;
};
export const activitiesGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    activityResource?: ResourceEnum,
    organisationId?: string,
    matterId?: string,
    matterStepId?: string,
    documentId?: string,
    enquiryId?: string,
    sortBy?: ActivitySortByEnum,
    sortDirection?: SortDirectionEnum,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<ActivityMetas>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/activities?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        activitiesTag()
      ],
    },
    ...requestInitArgsRest
  });

};
