/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  InviteRequestMetas,
  inviteRequestsPendingGetFetch,
  InviteRequestsPost,
  InviteRequest,
  inviteRequestsPostFetch,
  inviteRequestsInviteRequestIdAcceptPostFetch,
  inviteRequestsInviteRequestIdCancelPostFetch,
  inviteRequestsInviteRequestIdRejectPostFetch
} from '@/common/gen';

export const inviteRequestsPendingGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    matterId?: string,
    userId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<InviteRequestMetas>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await inviteRequestsPendingGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<InviteRequestMetas>({tag: 'inviteRequestsPendingGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as InviteRequestMetas, error: null };
};

export const inviteRequestsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: InviteRequestsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<InviteRequest>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await inviteRequestsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<InviteRequest>({tag: 'inviteRequestsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as InviteRequest, error: null };
};

export const inviteRequestsInviteRequestIdAcceptPost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteRequestId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<InviteRequest>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await inviteRequestsInviteRequestIdAcceptPostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<InviteRequest>({tag: 'inviteRequestsInviteRequestIdAcceptPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as InviteRequest, error: null };
};

export const inviteRequestsInviteRequestIdCancelPost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteRequestId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<InviteRequest>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await inviteRequestsInviteRequestIdCancelPostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<InviteRequest>({tag: 'inviteRequestsInviteRequestIdCancelPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as InviteRequest, error: null };
};

export const inviteRequestsInviteRequestIdRejectPost = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    inviteRequestId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<InviteRequest>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await inviteRequestsInviteRequestIdRejectPostFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<InviteRequest>({tag: 'inviteRequestsInviteRequestIdRejectPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as InviteRequest, error: null };
};
