/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  WorkflowStepsBulkUpdatePatch,
  WorkflowStepBulkUpdateRes,
  WorkflowSteps,
  WorkflowStepsPost,
  WorkflowStep,
  WorkflowStepsWorkflowStepIdPatch
} from '@/common/gen';

export const workflowStepsBulkUpdateTag = (): string => {
  return `/workflow-steps/bulk-update`;
};

export const workflowStepsTag = (): string => {
  return `/workflow-steps`;
};

export const workflowStepsWorkflowStepIdTag = ({
  pathParams,
}:{
  pathParams: {
    workflowStepId: string,
  },
}): string => {
  return `/workflow-steps/${encodeURIComponent(pathParams.workflowStepId)}`;
};
export const workflowStepsBulkUpdatePatchFetch = ({
  body,
  ...requestInitArgs
}:{
  body: WorkflowStepsBulkUpdatePatch,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<WorkflowStepBulkUpdateRes>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/workflow-steps/bulk-update`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        workflowStepsBulkUpdateTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowStepsGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    workflowId?: string,
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<WorkflowSteps>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/workflow-steps?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        workflowStepsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowStepsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: WorkflowStepsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<WorkflowStep>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/workflow-steps`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        workflowStepsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowStepsWorkflowStepIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    workflowStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<WorkflowStep>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/workflow-steps/${encodeURIComponent(pathParams.workflowStepId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        workflowStepsWorkflowStepIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const workflowStepsWorkflowStepIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: WorkflowStepsWorkflowStepIdPatch,
  pathParams: {
    workflowStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<WorkflowStep>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/workflow-steps/${encodeURIComponent(pathParams.workflowStepId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        workflowStepsWorkflowStepIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
