/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  MatterStepsApplyWorkflowPost,
  MatterWorkflowApplyRes,
  MatterStepsBulkUpdatePatch,
  MatterStepBulkUpdateRes,
  MatterSteps,
  MatterStepsPost,
  MatterStep,
  MatterStepMeta,
  MatterStepStatusEnum,
  MatterStepOverviews,
  MatterStepsAssignedPatch,
  MatterStepFormResponses,
  MatterStepsFormResponsesPost,
  MatterStepFormResponse,
  MatterStepsMatterStepIdPatch,
  MatterStepsStatusPatch
} from '@/common/gen';

export const matterStepsApplyWorkflowTag = (): string => {
  return `/matter-steps/apply-workflow`;
};

export const matterStepsBulkUpdateTag = (): string => {
  return `/matter-steps/bulk-update`;
};

export const matterStepsTag = (): string => {
  return `/matter-steps`;
};

export const matterStepsMetaTag = (): string => {
  return `/matter-steps/meta`;
};

export const matterStepsOverviewTag = (): string => {
  return `/matter-steps/overview`;
};

export const matterStepsMatterStepIdAssignedTag = ({
  pathParams,
}:{
  pathParams: {
    matterStepId: string,
  },
}): string => {
  return `/matter-steps/${encodeURIComponent(pathParams.matterStepId)}/assigned`;
};

export const matterStepsMatterStepIdFormResponsesTag = ({
  pathParams,
}:{
  pathParams: {
    matterStepId: string,
  },
}): string => {
  return `/matter-steps/${encodeURIComponent(pathParams.matterStepId)}/form-responses`;
};

export const matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdTag = ({
  pathParams,
}:{
  pathParams: {
    matterStepId: string,
    matterStepFormResponseId: string,
  },
}): string => {
  return `/matter-steps/${encodeURIComponent(pathParams.matterStepId)}/form-responses/${encodeURIComponent(pathParams.matterStepFormResponseId)}`;
};

export const matterStepsMatterStepIdTag = ({
  pathParams,
}:{
  pathParams: {
    matterStepId: string,
  },
}): string => {
  return `/matter-steps/${encodeURIComponent(pathParams.matterStepId)}`;
};

export const matterStepsMatterStepIdStatusTag = ({
  pathParams,
}:{
  pathParams: {
    matterStepId: string,
  },
}): string => {
  return `/matter-steps/${encodeURIComponent(pathParams.matterStepId)}/status`;
};
export const matterStepsApplyWorkflowPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: MatterStepsApplyWorkflowPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterWorkflowApplyRes>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matter-steps/apply-workflow`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        matterStepsApplyWorkflowTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsBulkUpdatePatchFetch = ({
  body,
  ...requestInitArgs
}:{
  body: MatterStepsBulkUpdatePatch,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStepBulkUpdateRes>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matter-steps/bulk-update`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        matterStepsBulkUpdateTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterSteps>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matter-steps?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        matterStepsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: MatterStepsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStep>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matter-steps`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        matterStepsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsMetaGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStepMeta>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matter-steps/meta?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        matterStepsMetaTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsOverviewGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepStatuses?: MatterStepStatusEnum[],
    assignedToMe?: boolean,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStepOverviews>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matter-steps/overview?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        matterStepsOverviewTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsMatterStepIdAssignedPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MatterStepsAssignedPatch,
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStep>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matter-steps/${encodeURIComponent(pathParams.matterStepId)}/assigned`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        matterStepsMatterStepIdAssignedTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsMatterStepIdFormResponsesGetFetch = ({
  pathParams,
  queryParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterStepId: string,
  },
  queryParams: {
    limit?: number,
    offset?: number,
    latest?: boolean,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStepFormResponses>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matter-steps/${encodeURIComponent(pathParams.matterStepId)}/form-responses?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        matterStepsMatterStepIdFormResponsesTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsMatterStepIdFormResponsesPostFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MatterStepsFormResponsesPost,
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStepFormResponse>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matter-steps/${encodeURIComponent(pathParams.matterStepId)}/form-responses`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        matterStepsMatterStepIdFormResponsesTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterStepId: string,
    matterStepFormResponseId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStepFormResponse>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matter-steps/${encodeURIComponent(pathParams.matterStepId)}/form-responses/${encodeURIComponent(pathParams.matterStepFormResponseId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        matterStepsMatterStepIdFormResponsesMatterStepFormResponseIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsMatterStepIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStep>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/matter-steps/${encodeURIComponent(pathParams.matterStepId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        matterStepsMatterStepIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsMatterStepIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MatterStepsMatterStepIdPatch,
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStep>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matter-steps/${encodeURIComponent(pathParams.matterStepId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        matterStepsMatterStepIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const matterStepsMatterStepIdStatusPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: MatterStepsStatusPatch,
  pathParams: {
    matterStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<MatterStep>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/matter-steps/${encodeURIComponent(pathParams.matterStepId)}/status`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        matterStepsMatterStepIdStatusTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
