/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  WorkflowStepsBulkUpdatePatch,
  WorkflowStepBulkUpdateRes,
  workflowStepsBulkUpdatePatchFetch,
  WorkflowSteps,
  workflowStepsGetFetch,
  WorkflowStepsPost,
  WorkflowStep,
  workflowStepsPostFetch,
  workflowStepsWorkflowStepIdGetFetch,
  WorkflowStepsWorkflowStepIdPatch,
  workflowStepsWorkflowStepIdPatchFetch
} from '@/common/gen';

export const workflowStepsBulkUpdatePatch = async ({
  body,
  ...requestInitArgs
}:{
  body: WorkflowStepsBulkUpdatePatch,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<WorkflowStepBulkUpdateRes>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowStepsBulkUpdatePatchFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<WorkflowStepBulkUpdateRes>({tag: 'workflowStepsBulkUpdatePatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as WorkflowStepBulkUpdateRes, error: null };
};

export const workflowStepsGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    workflowId?: string,
    limit?: number,
    offset?: number,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<WorkflowSteps>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowStepsGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<WorkflowSteps>({tag: 'workflowStepsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as WorkflowSteps, error: null };
};

export const workflowStepsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: WorkflowStepsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<WorkflowStep>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowStepsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<WorkflowStep>({tag: 'workflowStepsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as WorkflowStep, error: null };
};

export const workflowStepsWorkflowStepIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    workflowStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<WorkflowStep>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowStepsWorkflowStepIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<WorkflowStep>({tag: 'workflowStepsWorkflowStepIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as WorkflowStep, error: null };
};

export const workflowStepsWorkflowStepIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: WorkflowStepsWorkflowStepIdPatch,
  pathParams: {
    workflowStepId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<WorkflowStep>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowStepsWorkflowStepIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<WorkflowStep>({tag: 'workflowStepsWorkflowStepIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as WorkflowStep, error: null };
};
