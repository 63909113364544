/*
* This file is auto generated - do not edit manually
*/
"use client"

import { Route } from "next";
import { useRouter } from "next/navigation";
import useSWR, { SWRConfiguration } from "swr";
import { SwrError } from ".";
import {
  OrganisationPermissionsEnum,
  Organisations,
  organisationsGetFetch,
  organisationsTag,
  Organisation,
  organisationsOrganisationIdGetFetch,
  organisationsOrganisationIdTag,
  OrganisationMembers,
  organisationsOrganisationIdMembersGetFetch,
  organisationsOrganisationIdMembersTag,
  OrganisationPermissions,
  organisationsOrganisationIdPermissionsGetFetch,
  organisationsOrganisationIdPermissionsTag,
  OrganisationProfile,
  organisationsOrganisationIdProfileGetFetch,
  organisationsOrganisationIdProfileTag,
  OrganisationRoles,
  organisationsOrganisationIdRolesGetFetch,
  organisationsOrganisationIdRolesTag
} from '@/common/gen';

export const useOrganisationsGet = ({
    queryParams,
  }:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationPermission?: OrganisationPermissionsEnum,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<Organisations>(
    organisationsTag(),
    () => organisationsGetFetch({queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useOrganisationsOrganisationIdGet = ({
    pathParams,
  }:{
  pathParams: {
    organisationId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<Organisation>(
    organisationsOrganisationIdTag({ pathParams }),
    () => organisationsOrganisationIdGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useOrganisationsOrganisationIdMembersGet = ({
    pathParams,
    queryParams,
  }:{
  pathParams: {
    organisationId: string,
  },
  queryParams: {
    limit?: number,
    offset?: number,
    },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<OrganisationMembers>(
    organisationsOrganisationIdMembersTag({ pathParams }),
    () => organisationsOrganisationIdMembersGetFetch({pathParams, queryParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useOrganisationsOrganisationIdPermissionsGet = ({
    pathParams,
  }:{
  pathParams: {
    organisationId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<OrganisationPermissions>(
    organisationsOrganisationIdPermissionsTag({ pathParams }),
    () => organisationsOrganisationIdPermissionsGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useOrganisationsOrganisationIdProfileGet = ({
    pathParams,
  }:{
  pathParams: {
    organisationId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<OrganisationProfile>(
    organisationsOrganisationIdProfileTag({ pathParams }),
    () => organisationsOrganisationIdProfileGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}


export const useOrganisationsOrganisationIdRolesGet = ({
    pathParams,
  }:{
  pathParams: {
    organisationId: string,
  },
  },
  requestInitArgs?: RequestInit,
  swrOptions?: SWRConfiguration,
) => {
  const router = useRouter();

  const { data, error, isLoading, mutate, isValidating } = useSWR<OrganisationRoles>(
    organisationsOrganisationIdRolesTag({ pathParams }),
    () => organisationsOrganisationIdRolesGetFetch({pathParams, ...requestInitArgs}).then(async (response) => {
      if(response.status === 401) {
        router.push(`${process.env.NEXT_PUBLIC_FE_API_BASE_URL}/auth/sign-in` as Route);
        return;
      }

      const resBody = await response.json();

      if(!response.ok) {
        console.log({resBody, stringified: JSON.stringify(resBody), errorJson: new Error(resBody), errorString: JSON.stringify(resBody)})
        throw new SwrError(resBody.message, resBody.statusCode);
      }

      return resBody;
    }),
    swrOptions
  )
  return {
    data,
    isLoading,
    isError: !!error,
    error,
    mutate,
    isValidating
  }
}

