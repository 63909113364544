/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  Documents,
  DocumentsPost,
  Document,
  DocumentMeta,
  DocumentOverviews,
  DocumentUpload
} from '@/common/gen';

export const documentsTag = (): string => {
  return `/documents`;
};

export const documentsMetaTag = (): string => {
  return `/documents/meta`;
};

export const documentsOverviewTag = (): string => {
  return `/documents/overview`;
};

export const documentsDocumentIdTag = ({
  pathParams,
}:{
  pathParams: {
    documentId: string,
  },
}): string => {
  return `/documents/${encodeURIComponent(pathParams.documentId)}`;
};

export const documentsDocumentIdUploadTag = ({
  pathParams,
}:{
  pathParams: {
    documentId: string,
  },
}): string => {
  return `/documents/${encodeURIComponent(pathParams.documentId)}/upload`;
};
export const documentsGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepId?: string,
    formFieldId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Documents>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/documents?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        documentsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const documentsPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: DocumentsPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Document>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/documents`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        documentsTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const documentsMetaGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    matterId?: string,
    matterStepId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<DocumentMeta>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/documents/meta?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        documentsMetaTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const documentsOverviewGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    matterId?: string,
    matterStepId?: string,
    formFieldId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<DocumentOverviews>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/documents/overview?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        documentsOverviewTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const documentsDocumentIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    documentId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<Document>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/documents/${encodeURIComponent(pathParams.documentId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        documentsDocumentIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const documentsDocumentIdUploadGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    documentId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<DocumentUpload>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/documents/${encodeURIComponent(pathParams.documentId)}/upload`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        documentsDocumentIdUploadTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
