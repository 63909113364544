/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  AuthSignUpPost,
  User,
  UserInfo
} from '@/common/gen';

export const authCallbackTag = (): string => {
  return `/auth/callback`;
};

export const authSignInTag = (): string => {
  return `/auth/sign-in`;
};

export const authSignOutTag = (): string => {
  return `/auth/sign-out`;
};

export const authSignUpTag = (): string => {
  return `/auth/sign-up`;
};

export const authUserInfoTag = (): string => {
  return `/auth/user-info`;
};
export const authCallbackGetFetch = (requestInitArgs: Omit<RequestInit, 'body'>): Promise<HttpResponseType<void>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/auth/callback`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        authCallbackTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const authSignInGetFetch = ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    redirectURI?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<void>> => {
  const urlSearchParams = encodeQueryParameters(queryParams);

  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/auth/sign-in?${urlSearchParams}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        authSignInTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const authSignOutGetFetch = (requestInitArgs: Omit<RequestInit, 'body'>): Promise<HttpResponseType<void>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/auth/sign-out`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        authSignOutTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const authSignUpPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: AuthSignUpPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<User>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/auth/sign-up`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        authSignUpTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const authUserInfoGetFetch = (requestInitArgs: Omit<RequestInit, 'body'>): Promise<HttpResponseType<UserInfo>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/auth/user-info`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        authUserInfoTag()
      ],
    },
    ...requestInitArgsRest
  });

};
