/*
* This file is auto generated - do not edit manually
*/
import { encodeQueryParameters, HttpResponseType } from './codegen-index';
import { baseUrl } from './index';
import {
  StepTasksPost,
  StepTask,
  StepTasksStepTaskIdPatch
} from '@/common/gen';

export const stepTasksTag = (): string => {
  return `/step-tasks`;
};

export const stepTasksStepTaskIdTag = ({
  pathParams,
}:{
  pathParams: {
    stepTaskId: string,
  },
}): string => {
  return `/step-tasks/${encodeURIComponent(pathParams.stepTaskId)}`;
};
export const stepTasksPostFetch = ({
  body,
  ...requestInitArgs
}:{
  body: StepTasksPost,
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepTask>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/step-tasks`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
    next: {
      tags: [
        stepTasksTag()
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepTasksStepTaskIdGetFetch = ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    stepTaskId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepTask>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  return fetch(`${baseUrl}/step-tasks/${encodeURIComponent(pathParams.stepTaskId)}`, {
    headers,
    method: 'GET',
    next: {
      tags: [
        stepTasksStepTaskIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};

export const stepTasksStepTaskIdPatchFetch = ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: StepTasksStepTaskIdPatch,
  pathParams: {
    stepTaskId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<HttpResponseType<StepTask>> => {
  const { headers: reqHeaders, ...requestInitArgsRest } = requestInitArgs ?? {};

  let headers: Headers;

  if(reqHeaders) {
    headers = new Headers(reqHeaders);
  } else {
    headers = new Headers();
  }

  headers.set('Content-Type', 'application/json');

  return fetch(`${baseUrl}/step-tasks/${encodeURIComponent(pathParams.stepTaskId)}`, {
    body: JSON.stringify(body),
    headers,
    method: 'PATCH',
    next: {
      tags: [
        stepTasksStepTaskIdTag({pathParams}),
      ],
    },
    ...requestInitArgsRest
  });

};
