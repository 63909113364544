/*
* This file is auto generated - do not edit manually
*/
"use server"

import { notFound, redirect } from 'next/navigation';
import { cookies, headers } from 'next/headers';
import { ActionResult, handleError, signInUrl } from './index';
import {
  Workflows,
  workflowsGetFetch,
  WorkflowsPost,
  Workflow,
  workflowsPostFetch,
  WorkflowMeta,
  workflowsMetaGetFetch,
  WorkflowStatusEnum,
  WorkflowOverviews,
  workflowsOverviewGetFetch,
  workflowsWorkflowIdGetFetch,
  WorkflowsWorkflowIdPatch,
  workflowsWorkflowIdPatchFetch,
  WorkflowPermissions,
  workflowsWorkflowIdPermissionsGetFetch
} from '@/common/gen';

export const workflowsGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    organisationId?: string,
    matterId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Workflows>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowsGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Workflows>({tag: 'workflowsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Workflows, error: null };
};

export const workflowsPost = async ({
  body,
  ...requestInitArgs
}:{
  body: WorkflowsPost,
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Workflow>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowsPostFetch({
    body,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Workflow>({tag: 'workflowsPost', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Workflow, error: null };
};

export const workflowsMetaGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<WorkflowMeta>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowsMetaGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<WorkflowMeta>({tag: 'workflowsMetaGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as WorkflowMeta, error: null };
};

export const workflowsOverviewGet = async ({
  queryParams,
  ...requestInitArgs
}:{
  queryParams: {
    limit?: number,
    offset?: number,
    search?: string,
    workflowStatuses?: WorkflowStatusEnum[],
    organisationId?: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<WorkflowOverviews>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowsOverviewGetFetch({
    queryParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<WorkflowOverviews>({tag: 'workflowsOverviewGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as WorkflowOverviews, error: null };
};

export const workflowsWorkflowIdGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    workflowId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Workflow>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowsWorkflowIdGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Workflow>({tag: 'workflowsWorkflowIdGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Workflow, error: null };
};

export const workflowsWorkflowIdPatch = async ({
  body,
  pathParams,
  ...requestInitArgs
}:{
  body: WorkflowsWorkflowIdPatch,
  pathParams: {
    workflowId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<Workflow>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowsWorkflowIdPatchFetch({
    body,
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<Workflow>({tag: 'workflowsWorkflowIdPatch', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as Workflow, error: null };
};

export const workflowsWorkflowIdPermissionsGet = async ({
  pathParams,
  ...requestInitArgs
}:{
  pathParams: {
    workflowId: string,
  },
} & Omit<RequestInit, 'body'>): Promise<ActionResult<WorkflowPermissions>> => {
  const reqHeaders = new Headers();

  const reqCookies = cookies();

  reqHeaders.set('cookie', reqCookies.toString());

  const res = await workflowsWorkflowIdPermissionsGetFetch({
    pathParams,
    headers: reqHeaders,
    ...requestInitArgs
  });


  if(res.status === 401) {
    redirect(signInUrl);
  }

  if(res.status === 404) {
    notFound();
  }

  if(res.status >= 400) {
    return handleError<WorkflowPermissions>({tag: 'workflowsWorkflowIdPermissionsGet', res})  
  }

  // TODO: Implement handling non jsonable responses
  const responseBody = await res.json();

  return {data: responseBody as WorkflowPermissions, error: null };
};
