export enum TourPageEnum {
  Dashboard = 'dashboard',
  MatterDetailsPage = 'matter_details_page',
  MatterDocumentPage = 'matter_document_page',
  MatterDocumentsPage = 'matter_documents_page',
  MatterEnquiriesPage = 'matter_enquiries_page',
  MatterEnquiryPage = 'matter_enquiry_page',
  MatterMembersPage = 'matter_members_page',
  MatterOverviewPage = 'matter_overview_page',
  MatterProgressPage = 'matter_progress_page',
  MatterSettingsPage = 'matter_settings_page',
  MattersOverviewPage = 'matters_overview_page',
  OrganisationMembersPage = 'organisation_members_page',
  OrganisationPage = 'organisation_page',
  OrganisationSettingsPage = 'organisation_settings_page',
  OrganisationTemplateFormsPage = 'organisation_template_forms_page',
  OrganisationTemplateStepsPage = 'organisation_template_steps_page',
  OrganisationTemplateWorkflowsPage = 'organisation_template_workflows_page',
  OrganisationTemplatesPage = 'organisation_templates_page',
  OrganisationsPage = 'organisations_page',
  UserInvitesPage = 'user_invites_page',
  UserManageProfilePage = 'user_manage_profile_page',
  UserNotificationsPage = 'user_notifications_page',
  UserPublicPage = 'user_public_page',
  UserSettingsPage = 'user_settings_page',
};
