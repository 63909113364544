'use client';

export const uploadWithProgress = (url: string, file: File, progressCallback: (percentComplete: number) => void) => {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status !== 200) {
          rej(xhr);
        }
      }
    };

    xhr.onabort = (e) => {
      console.warn('upload aborted: ', e);
      rej(xhr);
    };

    xhr.onerror = (e) => {
      console.warn('upload error: ', e);
      rej(xhr);
    };

    xhr.ontimeout = (e) => {
      console.warn('upload timeout: ', e);
      rej(xhr);
    };

    xhr.onloadend = (e) => {
      console.log(e);
      res(xhr);
    };

    if (progressCallback) {
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentComplete = Math.ceil((e.loaded / file.size) * 100);
          progressCallback(percentComplete);
        }

        if (e.loaded === file.size) {
          res(xhr);
        }
      };
    }

    xhr.open('PUT', url);
    xhr.send(file);
  });
};
