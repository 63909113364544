import { HttpResponseType } from '../api-fetch';

export const signInUrl = process.env.FE_API_LOGIN_ROUTE ?? '/services/backend/v1/auth/sign-in';

export type ActionResult<T> = { data: T, error: null} | { data: null, error: { statusCode: number, message: string }};

export const handleError = async <T>({tag, res}: {tag: string, res: HttpResponseType<T>}): Promise<ActionResult<T>> => {
  console.log(`error ${res.status} - ${tag}`);
  
  const errorRes = await res.json();
  
  console.error(errorRes);

  return {
    data: null,
    error: {
      statusCode: res.status,
      message: errorRes.message,
    }
  }
}

export * from './codegen-index';
